<template>
<div style='background: #fff;padding:15px'>
  <div class="filter-item">
    <el-input v-model="keyWords" @keyup.enter.native="goodsSearch" placeholder="请输入关键字" style="width: 250px;;margin-right: 10px;" clearable></el-input>
    <el-button type="primary" @click="goodsSearch">查询</el-button>
  </div>
  <div class="filter-container" v-loading='loading'>
    <div class="left">
      <div class="top">
        <img :src='imgUrl+mallInfo.MallLogoUrl' v-if="mallInfo.MallLogoUrl!=''">
        <div class="merchant-name">{{mallInfo.MallName}}</div>
      </div>
      <div class="bottom">
        <div>
          <img  style="margin-right:10px;width:25px;" src='http://cdn.dkycn.cn/images/melyshop/address-wechat-icon.jpg'>
          <span>客服微信：{{mallInfo.CustomerServiceWx==''?'暂无':mallInfo.CustomerServiceWx}}</span>
        </div>
        <div>
          <img style="margin-right:10px;width:25px;" src='http://cdn.dkycn.cn/images/melyshop/address-phone-num-icon.png'>
          <span>客服电话：{{mallInfo.CustomerServicePhone==''?'暂无':mallInfo.CustomerServicePhone}}</span>
        </div>
        <div>
          <img style="margin-right:10px;width:25px;" src="@/assets/img/ico.png" alt="" > 
          <span style="line-height:1.5">供应商简介： 
            {{mallInfo.SupplierIntroduction==''?'供货商未填简介':mallInfo.SupplierIntroduction}}
          </span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="detail-container">
        <div class="detail-imgs">
          <img class="plan-img" :src="imgUrl+planImg.src" v-if="planImg.type=='images'">
          <video class="plan-img"  :src="imgUrl+planImg.src" v-else controls id="planVideo" controlsList="nofullscreen nodownload noremoteplayback"></video>
          <div class="imgs-content">
            <i class="el-icon-arrow-left icon-left" @click="handleLeft" v-show="marginIndex"></i>
            <i class="el-icon-arrow-left icon-unleft"  v-show="!marginIndex"></i>

            <div class="imgs-list">
              <ul :style="{transform:`translateX(-${marginIndex*300}px)`}">
                <li class="imgs-item" v-for="(item,index) in imgsList" :key="index">
                  <video v-if="item.type=='video'" :src="imgUrl+item.src" @click="showAsPlanImg(index)"></video>
                  <img v-else :src="imgUrl+item.src" @click="showAsPlanImg(index)"> 
                </li>
              </ul>
            </div>
            
            <i class="el-icon-arrow-right icon-right" @click="handleRight" v-show="marginIndexRight"></i>
            <i class="el-icon-arrow-right icon-unright"  v-show="!marginIndexRight"></i>
          </div>
        </div>
        <div class="detail-message">
          <div class="message-name">
            {{renderData.Name}}
          </div>
          <div class="message-price">
            <p>利润率：
              <span style="color:#FDA23C">{{renderData.ProfitRateMin}}%</span>
              <span style="color:#FDA23C" v-if='renderData.ProfitRateMin!=renderData.ProfitRateMax'>-{{renderData.ProfitRateMax}}%</span>
            </p>
            <p>供货价：
              <span>￥{{renderData.SupplierMallPriceMin}}</span>
              <span  v-if='renderData.SupplierMallPriceMin!=renderData.SupplierMallPriceMax'>~￥{{renderData.SupplierMallPriceMax}}</span>
            </p>
            <p>建议售价：
              <span>￥{{renderData.RetailPriceMin}}</span>
              <span v-if='renderData.RetailPriceMin!=renderData.RetailPriceMax'>~￥{{renderData.RetailPriceMax}}</span>
            </p>
          </div>
          <div class="message-freight">
            <span>运费：</span>
            <el-popover
              placement="bottom-start"
              width="300"
              trigger="click">
              <div class="message-freight-table-box">
                <table class="message-freight-table" width="100%" border="0"  cellspacing="1" cellpadding="0"> 
                  <tr v-for="(item,index) in freightTemplateData"  :key="index">
                    <td class="td1">{{item.areaNames}}</td>
                    <td>{{item.rules}}</td>
                  </tr>
                </table>
              </div>
              <span v-show="renderData.SupplierFreightType> 0" slot="reference" class="btn">运费计费规则</span>
            </el-popover>
            <span v-if="renderData.SupplierFreightType== 0 && renderData.SupplierUniformFreight> 0">{{renderData.SupplierUniformFreight}}元</span>
            <span v-if="renderData.SupplierFreightType== 0 && renderData.SupplierUniformFreight== 0">免运费</span>
          </div>
          <div class="message-sales">
            <p v-if='renderData.SellCount >100000||renderData.SellCount ==100000'>总销量：<span>{{Math.floor(renderData.SellCount /10000)}}万+件</span></p>
            <p v-else>总销量：<span>{{renderData.SellCount}}件</span></p>
            <p>总库存：<span>{{renderData.Stock}}件</span></p>
          </div>
          <div class="message-space">
            <div class="space-line">规格：</div>
            <div class="space-detail-1" v-if="spaceList.length">
              <div>{{renderData.SpecTitle}}</div>
              <div class="space-list">
                <div class="space-detail-1-btn" v-for="(item,index) in spaceList" :key="index"  :class="{'current-btn':currentBtn == item.value1}" @click="changeSpace(index)">{{item.value1}}</div>
              </div>
            </div>
            <div class="space-detail-2">
              <div>{{renderData.SpecTitle2}}</div>
              <ul>
                <li v-for="item in currentValueList" :key='item.SpecValue'>
                  <span class="detail-1">{{item.SpecValue}}</span>
                  <span class="detail-2">{{item.SupplierMallPrice}}</span>
                  <span class="detail-3">{{item.Stock}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="message-button" v-show='!renderData.IsAddedToMall'>
            <el-button type="primary" @click="handleAddMarket">上架到商城</el-button>
            <el-button type="primary" @click="handleAddWarehouse">添加到仓库</el-button>
          </div>
          
          <div class="message-button" v-show='renderData.IsAddedToMall'>
            <div class="message-button-added" >已添加到商城</div>
          </div>
        </div>
      </div>
      <div class="tp" style="width:825px">
          <el-tabs type="border-card">
           <el-tab-pane label="商品详情">
           <div  class="detail-content">
               <div v-if="renderData.Detail==''" style="margin-top:30px;color:#909399;">暂无</div>
               <div v-else v-html="renderData.Detail"></div>  
           </div>      
           </el-tab-pane>
           <el-tab-pane label="供货商资质">
               <div v-if="mallInfo.SupplierIllustrate==''" style="margin-top:30px;color:#909399;">供货商暂未上传说明</div>
               <div v-else v-html="mallInfo.SupplierIllustrate"></div> 
          </el-tab-pane>
          </el-tabs>  
      </div>
    </div>
  </div>
</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	export default {
		data() {
			return {
        loading: false,
        mallInfo: {
          MallLogoUrl: "",
          MallName: "",
          CustomerServicePhone: "",
          CustomerServiceWx: ""
        },
        freightData: [],
        renderData: {},
        spaceList: [],
        imgUrl: config.IMG_BASE,
        keyWords: '',
        imgsList:[],
        planImg: {
          type:'',
          src:''
        },
        planImgs: '',
        marginIndex: 0,
        marginIndexRight: false,
        currentBtn: '',
        currentValueList:[],
        queryData: {
          SupplierMallId: null,
          SupplierProductId: null
        },

        freightTemplateData: []
      }
    },
    created(){
      this.queryData = this.$route.query
      this.getData()
      this.getInfo()
    },
		methods: {
      async getInfo(){
        this.loading = true
        try{
          let result = await apiList.supplierMarketMallInfo(this.queryData)
          console.log(result)
          this.mallInfo = result.Result
        } catch(e){
          this.loading = false
        } finally{
          this.loading = false
        }
      },
      async getData(){
        this.loading = true
        try{
          let result = await apiList.supplierMarketProductInfo(this.queryData)
          this.renderData = result.Result
          this.computedImgsList()
          this.computedSpace(result.Result.ProductSpecList)
          if(this.renderData.SupplierFreightTemplateId ) {
            this.getFreightTemplate()
          }
        } catch(e){
          this.loading = false
        } finally{
          this.loading = false
        }
      },
      async getFreightTemplate() {
        try{
          let result = await apiList.freightTemplate({
            SupplierProductId: this.queryData.SupplierProductId,
            SupplierMallId: this.queryData.SupplierMallId
          })
          let areaNames = ''
          let rules = ''
          this.freightTemplateData = result.Result.DetailList.map(t => {
            areaNames = t.AreaNames.join(' ')
            if(result.Result.FreightTemplateType) {
              rules= `首重${t.FirstWeightToNumber}KG${t.FirstWeightMoney}元，
              每续重${t.ContinueWeightToNumber}KG${t.ContinueWeightMoney}元`
            }else {
              rules= `首${t.FirstCountToNumber}件${t.FirstMoneyToNumber}元，
              每续${t.ContinueCountToNumber}件${t.ContinueMoneyToNumber}元`
            }
            return {
              areaNames: areaNames,
              rules: rules
            }
          })
          if(result.Result.NonDistributionAreaNames.length ) {
            areaNames = result.Result.NonDistributionAreaNames.join(' ')
            this.freightTemplateData.push({
              areaNames: areaNames,
              rules: '不配送'
            })
          }
        } catch(e){}
      },
      computedImgsList(){
        this.imgsList = []
          if(this.renderData.VideoUrl!=''){
            this.imgsList.push(
              {
                type: 'video',
                src: this.renderData.VideoUrl
              }
            )
          }
          
          this.renderData.AttachedImgUrls.forEach(t=>{
            let item = {
              type: 'images',
              src: t
            }
            this.imgsList.push(item)
          })

          this.planImg = this.imgsList[0]

          this.marginIndexRight = (this.imgsList.length - 4 > 0)

      },
      computedSpace(list){
        //没有二级规格
        if(list[0].SpecValue2 ==''){
          this.currentValueList = list.map((t,i)=>{
            t.Stock = t.Stock <100000 ? t.Stock : Math.floor(t.Stock /10000)+'万+'
            return {
              SpecValue: t.SpecValue == '' ? '默认规格':t.SpecValue,
              Stock: t.Stock + '件可售',
              SupplierMallPrice: '￥'+ t.SupplierMallPrice
            }
          })
          return 
        }
          this.spaceList = []
          list.forEach((t,i) => {
            let item = {
              value1:t.SpecValue,
              value2List:[]
            }
            this.spaceList.push(item)
          });
          //去重
          var obj = {};
          this.spaceList = this.spaceList.reduce((item, next)=> {
              obj[next.value1] ? '' : obj[next.value1] = true && item.push(next);
              return item;
          }, []);

          list.forEach((t,i) => {
            this.spaceList.forEach((tt,ii)=>{
              if(tt.value1 == t.SpecValue){
                t.Stock = t.Stock <100000 ? t.Stock : Math.floor(t.Stock /10000)+'万+'
                tt.value2List.push({
                  SpecValue: t.SpecValue2,
                  Stock: t.Stock + '件可售',
                  SupplierMallPrice: '￥'+t.SupplierMallPrice
                })
              }
            })
          })

          this.currentBtn = this.spaceList[0].value1
          this.currentValueList = this.spaceList[0].value2List
      },
      async handleAddWarehouse(MallId,Id){
        this.loading = true
        try {
          let result = await apiList.supplierMarketProductSave({
            SupplierMallId: this.queryData.SupplierMallId,
            SupplierProductId: this.queryData.SupplierProductId,
            IsOpen: false
          })
          if(result.IsSuccess){
            this.$message({
              showClose:true,
              type:'success',
              message:'操作成功'
            })
            this.getData()
          }else{
					  this.loading = false
          }
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
        }
      },
      async  handleAddMarket(){
        this.loading = true
        try {
          let result = await apiList.supplierMarketProductSave({
            SupplierMallId: this.queryData.SupplierMallId,
            SupplierProductId: this.queryData.SupplierProductId,
            IsOpen: true
          })
          if(result.IsSuccess){
            this.$message({
              showClose:true,
              type:'success',
              message:'操作成功'
            })
            this.getData()
          }else{
					  this.loading = false
          }
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
        }
      },
      goodsSearch(){
        this.$router.push({
          path:'supplyMarket',
          query:{
            keyWords: this.keyWords
          }
        })
      },
      changeSpace(index){
        this.currentBtn = this.spaceList[index].value1
        this.currentValueList = this.spaceList[index].value2List
      },
      handleLeft(){
        if(this.marginIndex > 0){
          this.marginIndex -= 1
        }
        this.marginIndexRight = (this.imgsList.length - this.marginIndex*4 - 4)>0
      },
      handleRight(){
        let total = this.imgsList.length
        let current = 4 * (this.marginIndex + 1)
        if(((total-current)/4)>0){
          this.marginIndex += 1
        }
        this.marginIndexRight = (this.imgsList.length - this.marginIndex*4 - 4)>0
      },
      showAsPlanImg(index){
        this.planImg = this.imgsList[index]
      }
    }
	}
</script>

<style lang="less" >
// 不要加scoped
  .message-freight-table-box {
      max-height: 500px !important;
      overflow: auto;
     

  }
  .message-freight-table {
    tr td{
      position: relative;
      padding: 5px;
      width: 50%;
      border-bottom: 1px solid #ddd;
    }
    .td1::after {
      position:absolute;
      right: 0;
      content: '';    
      top: 10%;
      height: 80%;
      border-left: 1px solid #ddd;
    }
    table tr:last-child  td{
      border-bottom: 0px;
    }
  }
  .message-freight-table tr:last-child  td{
    border-bottom: 0px;
  }
</style>
<style lang="less" scoped>
.y{
  margin-top: -20px;
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
video::-internal-media-controls-download-button {
    display: none;
}
video::-webkit-media-controls-enclosure {
    overflow: hidden;
}


ul,li{ padding:0;margin:0;list-style:none}
.filter-container{
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  .left{
    margin-top: 20px;
    width: 370px;
    background-image: pink;
    display: flex;
    flex-direction: column;
    .top{
      height: 64px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img{
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
      .merchant-name{
        margin-left: 10px;
        width: 200px;
        height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        overflow: hidden;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .bottom{
      font-size: 14px;
      color: #909399;
    }
  }
  .right{
    .detail-container{
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      .detail-imgs{
        .plan-img{    
          border-radius: 5px;
          border: 1px solid #ddd;
          width: 350px;
          height: 350px;
          object-fit: contain;
          margin-bottom: 10px;
        }
        .plan-video{
          border-radius: 5px;
          border: 1px solid #ddd;
          width: 350px;
          height: 350px;
          margin-bottom: 10px;
        }
        .imgs-content{
          width: 350px;
          position: relative;
          display: flex;
          flex-direction: row;
          .icon-left{
            cursor: pointer;
            position: absolute;
            left: -20px;
            font-size: 45px;
            line-height: 65px;
            color: #909399;
          }
          .icon-unleft{
            position: absolute;
            left: -20px;
            font-size: 45px;
            line-height: 65px;
            color: #ddd;
          }
          .icon-right{
            cursor: pointer;
            position: absolute;
            right: -20px;
            font-size: 45px;
            line-height: 65px;
            color: #909399;
          }
          .icon-unright{
            position: absolute;
            right: -20px;
            font-size: 45px;
            line-height: 65px;
            color: #ddd;
          }

        }
        .imgs-list{
          width: 300px;
          margin-left: 25px;
          overflow: hidden;
          ul{
            display: flex;
            flex-direction: row;
            transform: translateX(0px);
            transition: all 1s ease;
          }
          .imgs-item{
            img{
              cursor: pointer;
              border: 1px solid #ddd;
              border-radius: 5px;
              margin: 0 5px;
              width: 65px;
              height: 65px;
              object-fit: contain;
            }
            video{
              cursor: pointer;
              border: 1px solid #ddd;
              border-radius: 5px;
              margin: 0 5px;
              width: 65px;
              height: 65px;
            }
          }
        }
      }
     
      .detail-message{
        margin-left: 20px;
        width: 450px;
        font-size: 14px;
        .message-name{
          font-size: 16px;
          width: 100%;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; 
        }
        .message-price{
          width: 100%;
          margin-top: 10px;
          padding:5px 10px;
          overflow: hidden;
          background-color: #eee;
        }
        .message-freight {
          margin-top: 10px;
          .btn {
            cursor: pointer;
            color: #409EFF;
          }
        }
        .message-space{
          .space-line{
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
          }
          .space-detail-1{
            margin-top: 10px;
            padding: 10px 10px 10px 20px;
            display: flex;
            flex-direction: row;
            .space-list{
              width: 370px;
              margin-left: 5px;
            }
            .space-detail-1-btn{
              display: inline-block;
              cursor: pointer;
              padding: 0 10px;
              line-height: 30px;
              border-radius: 2px;
              text-align: center;
              margin-right: 10px;
              margin-bottom: 5px;
              border: 2px solid #ddd;

            }
            .current-btn{
              border: 2px solid #FDA23C;
              
            }
          }
          .space-detail-2{
            padding: 10px 10px 10px 20px;
            display: flex;
            flex-direction: row;
            li{
              width: 370px;
              display: flex;
              flex-direction: row;
              padding: 0 10px;
              margin-bottom: 10px;
              .detail-1{
                width: 150px;
                margin-right: 20px;
              }
              .detail-2{
                width:100px;
              }
              .detail-3{
                width:100px;
                text-align: right;
              }
            }
          }
        }
        .message-button{
			    text-align: center;
        }
        .message-button-added{
          display: inline-block;
			    text-align: center;
          width:150px;
          background-color:#F56C6C;
          line-height:36px;
          text-align:center;
          color:#fff;
        }
      }

    }
    .detail-content{
      margin-top: 20px;
      width: 830px;
      ::v-deepimg{
        width: 100%  !important;
        object-fit: contain !important;
      }
      ::v-deepdiv{
        width: 100%  !important;
        text-align: center;
        overflow: hidden;
      }
      ::v-deepp{
        width: 100%  !important;
        text-align: center;
        overflow: hidden;
      }
      .content-title{
        background-color: #eee;
        line-height: 50px;
        text-align: center;
        
      }
    }
  }
}
</style>
